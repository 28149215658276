import React from 'react'
import './styles.scss'
import { Row, Col } from 'react-bootstrap'
import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'
import ThemeContext from '../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faMap } from '@fortawesome/free-regular-svg-icons'

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            error: false,
            show: false
        }
        this.show = this.show.bind(this)
    }
    static contextType = ThemeContext


    show() {
        this.setState({ show: true })
    }

    check(val) {
        if (this.state.error && val === "") {
            return false
        } else {
            return true
        }
    }

    submit() {
        if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
            this.setState({ error: true })
        } else {
            this.setState({ error: false })
        }
    }
    render() {
        return (
            <section id={`${this.props.id}`} className="contact" style={{ height: this.context.height }}>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText text="Contact" revealDuration={500} revealDelay={500} parentMethod={this.show} callMethodTime={1100} />
                        </h2>
                    </Col>
                    <Col md={5} className="form">
                        {this.form()}
                    </Col>
                    {/* <Col md={5} className="map">
                        {this.map()}
                    </Col> */}
                    <Col md={5} className="form">
                        <AnimationContainer delay={1350} animation="fadeInDown fast">
                            <div className="social social_icons">
                                <h4>Where to reach me?</h4>
                                <FontAwesomeIcon icon={faEnvelope} className="social_icon" onClick={() => window.open('mailto:uzair.bin.tariq@outlook.com')} />
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/uzair-bin-tariq/')} />
                                <FontAwesomeIcon icon={faStackOverflow} className="social_icon" onClick={() => window.open('https://stackoverflow.com/users/11120663/uzairtariq')} />
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://github.com/UzairBinTariq')} />
                                <h5 className="mt-50">© Copyright 2020. All Rights Reserved.</h5>
                            </div>
                        </AnimationContainer>
                    </Col>
                </Row>
            </section>
        )
    }

    form() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={0} animation="fadeInUp fast">
                    <div className="form-container">
                        <div className="line-text">
                            <h4>Get In Touch</h4>
                            <AnimationContainer delay={50} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" className={`name ${this.check(this.state.name) ? "" : "error"}`} placeholder="Name" onChange={e => this.setState({ name: e.target.value })} />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={100} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" className={`email ${this.check(this.state.email) ? "" : "error"}`} placeholder="Email" onChange={e => this.setState({ email: e.target.value })} />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={150} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" className="phone" placeholder="Phone" onChange={e => this.setState({ phone: e.target.value })} />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={200} animation="fadeInUp fast">
                                <div className="form-group">
                                    <textarea className={`message ${this.check(this.state.message) ? "" : "error"}`} placeholder="Message" onChange={e => this.setState({ message: e.target.value })}></textarea>
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={250} animation="fadeInUp fast">
                                <div className="submit">
                                    <button className={`hover-button ${this.state.error ? "error" : ""}`} onClick={() => this.submit()}>
                                        <span>Send Message</span>
                                    </button>
                                </div>
                            </AnimationContainer>
                        </div>
                    </div>
                </AnimationContainer>
            )
        }
    }

    map() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={1000} animation="fadeIn fast" height={this.context.height}>
                    <iframe title="map" width="100%" height="100%" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" />
                </AnimationContainer>
            )
        }
    }

}

export default Contact