import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Education extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={12} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>Experience</h4>
                            </div>
                            <h3>Where do I work?</h3>
                            {/* <div className="separator" /> */}
                            <h4 className="company-title"><span className="logo logo-tkxel"></span>Tkxel</h4>
                            <p>Senior Software Engineer</p>
                            <p>June 2019 — August 2019</p>
                            <p className="mt-40">Software Engineer</p>
                            <p>June 2017 — June 2019</p>
                            {/* <div className="separator" /> */}
                            <h4 className="company-title mt-50"><span className="logo logo-propelics"></span>Propelics, Inc.</h4>
                            <p>Software Engineer | Remote</p>
                            <p>June 2017 — December 2017</p>
                        </div>
                    </Col>
                    
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 60,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Education