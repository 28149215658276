import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faMap } from '@fortawesome/free-regular-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Skills extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{ height: this.context.height }}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>Skills</h4>
                            </div>
                            <h3>What I'm good at?</h3>
                            <div className="separator" />
                            <p><strong>Operating Systems:</strong> Windows, Linux</p>
                            <p><strong>Programming Languages:</strong> C/C++, C#, Java, JavaScript, Python, HTML5, CSS3, PHP, SQL, XML</p>
                            <p><strong>Frameworks:</strong> AngularJS, Angular2+, React.js, Node.js, GraphQL, Spring, .NET Core, Django, REST Framework, Flask</p>
                            <p><strong>Machine Learning Frameworks:</strong> Scikit-learn, PyTorch, Tensorflow, Keras, OpenCV</p>
                            <p><strong>Database Management Systems:</strong> MySQL, PostgreSQL, MongoDB, Cassandra, Elasticsearch</p>
                            <p><strong>Cloud-Based Systems:</strong> Google Cloud Platform, Microsoft Azure, Amazon Web Services</p>
                            <p><strong>Containerization:</strong> Docker, Kubernetes</p>
                            <p><strong>Configuration and Project Management Tools:</strong> Git, Jenkins, CI/CD, Jira</p>
                            <p><strong>Software Development Life Cycle Model:</strong> Agile</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faEnvelope} className="social_icon" onClick={() => window.open('mailto:uzair.bin.tariq@outlook.com')} />
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/uzair-bin-tariq/')} />
                                <FontAwesomeIcon icon={faStackOverflow} className="social_icon" onClick={() => window.open('https://stackoverflow.com/users/11120663/uzairtariq')} />
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://github.com/UzairBinTariq')} />
                            </div>                        </div>
                    </Col>
                    <Col md={6} className="skills">
                        <div className="line-text">
                            <h4>Expertise</h4>
                        </div>
                        <div className="skills-container">
                            <Progress name="Fronend Development" value={90} delay={1100} />
                            <Progress name="Backend Development" value={90} delay={1100} />
                            <Progress name="Systems Design" value={80} delay={1100} />
                            <Progress name="Cloud Technologies" value={85} delay={1100} />
                            <Progress name="DevOps" value={70} delay={1100} />
                            <Progress name="Project Management" value={75} delay={1100} />
                        </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
                }} />
        )
    }

}

export default Skills