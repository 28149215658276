import React from 'react'
import Particles from 'react-particles-js';
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Education extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{ height: this.context.height }}>
                {this.particles()}
                <Row>
                    <Col md={12} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>Education</h4>
                            </div>
                            <h3>Where do I Study?</h3>
                            {/* <div className="separator" /> */}
                            <h4 className="education-title"><span className="logo logo-sbu"></span>Stony Brook University</h4>
                            <p>Master of Science in Computer Science</p>
                            <p>August 2019 – December 2020*</p>
                            <p>New York, United States</p>
                            {/* <div className="separator" /> */}
                            <h4 className="education-title mt-50"> <span className="logo logo-giki"></span>GIK Institute of Engineering Sciences and Technology</h4>
                            <p>Bachelor of Science in Computer Engineering</p>
                            <p>Deans' Roll of Honor for Academic Distinction</p>
                            <p>August 2013 – May 2017</p>
                            <p>Topi, Pakistan</p>                            
                        </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 60,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
                }} />
        )
    }

}

export default Education