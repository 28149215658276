import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube, faStackOverflow } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faMap } from '@fortawesome/free-regular-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{ height: this.context.height }}>
                {this.particles()}
                <Row>
                    <Col md={12} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>Who am I?</h3>
                            {/* <div className="separator" /> */}
                            <p>Computer Science graduate student at Stony Brook University with 2+ years of work experience in the industry as a Software Engineer. Contributed at each level of software development life cycle, including requirement gathering, analyzing business goals, planning and designing micro-services architecture, product development, and deployment of high quality, functional, reliable, and fully testable applications.</p>
                            <p>Proficient in C/C++, C#, Python, JavaScript, Java, and leveraged modern frameworks to build end-to-end client-server applications ensuring security protocols over the network and cross-platform optimization and responsiveness. Experienced in formulating techniques to enhance production-ready applications and re-written source code to align best practices, confirming scalability and availability.</p>
                            <p>Highly motivated and enthusiastic towards Software Engineering, Machine Learning, Cloud Technologies, and Virtualization</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faEnvelope} className="social_icon" onClick={() => window.open('mailto:uzair.bin.tariq@outlook.com')} />
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/uzair-bin-tariq/')} />
                                <FontAwesomeIcon icon={faStackOverflow} className="social_icon" onClick={() => window.open('https://stackoverflow.com/users/11120663/uzairtariq')} />
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://github.com/UzairBinTariq')} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 60,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
                }} />
        )
    }

}

export default Hero